<template>
  <div>
    <my-nav-bar
      title="客户签单"
      left-text="返回"
      left-arrow
      right-text="添加欠条"
      @click-right="toClientView"
    />
    <div class="fixed-container">
      <van-cell v-if="isSuperman" class="store-seletor" title="选择门店" :value="showName" is-link center @click="show = true" />
      <van-search v-model="listQuery.client_name" placeholder="请输入客户名称" @search="onSearch" />
      <van-cell title="总签单" :value="'￥' + totalUnpaidAmount" value-class="cell-value" center />
      <van-cell title="总欠条" :value="'￥' + totalSurplusAmount" :border="false" value-class="cell-value" center />
    </div>
    <div>
      <van-list ref="list" v-model="loading" :class="isSuperman ? 'superman-list' : 'list'" :finished="finished" @load="getList">
        <div v-if="list.length > 0" class="tips">提示：往左滑动查看欠条</div>
        <van-empty v-if="showEmpty" description="暂无数据" />
        <van-swipe-cell
          v-for="(client) in list"
          :key="client.id"
        >
          <van-cell
            :title="client.name"
            value-class="cell-value"
            is-link
            center
            @click="toDetailView(client.id)"
          >
            <div v-if="client.unpaid_amount != 0">
              签单:￥{{ client.unpaid_amount }}
            </div>
            <div v-if="client.surplus_amount != 0">
              欠条:￥{{ client.surplus_amount }}
            </div>
          </van-cell>
          <template #right>
            <van-button style="height: 100%" square type="primary" text="欠条" @click="toIouView(client)" />
          </template>
        </van-swipe-cell>
      </van-list>
    </div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
    <my-dialog v-model="showIouDialog" title="添加欠条" @confirm="addIouConfirm">
      <div class="iou-container">
        <van-cell title="客户名称" :value="iouData.client_name" center />
        <van-field v-model="iouData.iou_amount" label="欠条金额" input-align="right" placeholder="请输入欠条金额" type="number" />
        <van-cell title="欠条日期" :value="iouData.iou_date" is-link center @click="showDatePicker = true" />
      </div>
    </my-dialog>
    <van-popup v-model="showDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        @confirm="handleSelectDate"
        @cancel="showDatePicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getClientUnpaids, addClientIou } from '@/api/client-unpaid'
import MyDialog from '@/components/my-dialog'
import { toDateString } from '@/utils'
import { getStores } from '@/api/home'
export default {
  name: 'ClientUnpaid',
  components: { myNavBar, MyDialog },
  data() {
    return {
      loading: false,
      finished: false,
      listQuery: {
        page: 0,
        limit: 10,
        store_id: 0,
        client_name: ''
      },
      showName: '全部',
      list: [],
      show: false,
      actions: [
        { name: '全部', store_id: 0 }
      ],
      showEmpty: false,
      totalUnpaidAmount: 0,
      totalSurplusAmount: 0,
      isSuperman: this.$store.state.userInfo.type === 1,
      showIouDialog: false,
      iouData: {
        client_id: '',
        client_name: '',
        iou_amount: '',
        iou_date: ''
      },
      showDatePicker: false,
      currentDate: new Date()
    }
  },
  created() {
    this.$store.commit('setKeepAlive', this.$options.name)
    this.$eventBus.$on('handle-iou', (res) => {
      this.iouData.client_id = res.id
      this.iouData.client_name = res.name
      this.$nextTick(() => {
        setTimeout(() => {
          this.showIouDialog = true
        }, 100)
      })
    })

    getStores().then(res => {
      res.data.map(store => {
        this.actions.push({
          store_id: store.id,
          name: store.name
        })
      })
    })
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path === '/home') {
      this.$store.commit('rmKeepAlive', this.$options.name)
      this.$eventBus.$off('handle-iou')
    }
    next()
  },
  methods: {
    getList() {
      this.listQuery.page++
      this.showEmpty = false
      getClientUnpaids(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
        this.totalUnpaidAmount = res.data.total_unpaid_amount
        this.totalSurplusAmount = res.data.total_surplus_amount
      })
    },
    onSelect(action) {
      this.showName = action.name
      this.listQuery.store_id = action.store_id
      this.reload()
    },
    toDetailView(clientId) {
      this.$router.push({
        path: 'client-unpaid-detail',
        query: {
          client_id: clientId
        }
      })
    },
    reload() {
      this.listQuery.page = 0
      this.list = []
      this.finished = false
      this.show = false
      this.$refs.list.check()
    },
    onSearch() {
      this.reload()
    },
    handleAddIou() {
      this.showIouDialog = true
      this.iouData.iou_amount = ''
      this.iouData.iou_date = ''
    },
    toClientView() {
      this.$router.push({
        path: '/client-unpaid-select',
        query: {
          store_id: this.listQuery.store_id
        }
      })
    },
    handleSelectDate(date) {
      this.iouData.iou_date = toDateString(date)
      this.showDatePicker = false
    },
    addIouConfirm() {
      this.beginLoad()
      addClientIou(this.iouData).then(res => {
        this.success(res.msg)
        this.reload()
      })
    },
    toIouView(client) {
      this.$router.push({
        path: '/client-unpaid-iou',
        query: {
          client_id: client.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/color.scss";
  .fixed-container {
    position: fixed;
    top: 46px;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .cell-value {
    color: $amountRedColor;
  }
  .superman-list {
    margin-top: 242px;
  }
  .list {
    margin-top: 198px;
  }
  .iou-container {
    padding: 10px;
  }
  .tips {
    padding: 0 10px 10px 10px;
    color: $grayTextColor;
  }
</style>
