var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "客户签单",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "添加欠条"
    },
    on: {
      "click-right": _vm.toClientView
    }
  }), _c('div', {
    staticClass: "fixed-container"
  }, [_vm.isSuperman ? _c('van-cell', {
    staticClass: "store-seletor",
    attrs: {
      "title": "选择门店",
      "value": _vm.showName,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.show = true;
      }
    }
  }) : _vm._e(), _c('van-search', {
    attrs: {
      "placeholder": "请输入客户名称"
    },
    on: {
      "search": _vm.onSearch
    },
    model: {
      value: _vm.listQuery.client_name,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "client_name", $$v);
      },
      expression: "listQuery.client_name"
    }
  }), _c('van-cell', {
    attrs: {
      "title": "总签单",
      "value": '￥' + _vm.totalUnpaidAmount,
      "value-class": "cell-value",
      "center": ""
    }
  }), _c('van-cell', {
    attrs: {
      "title": "总欠条",
      "value": '￥' + _vm.totalSurplusAmount,
      "border": false,
      "value-class": "cell-value",
      "center": ""
    }
  })], 1), _c('div', [_c('van-list', {
    ref: "list",
    class: _vm.isSuperman ? 'superman-list' : 'list',
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.list.length > 0 ? _c('div', {
    staticClass: "tips"
  }, [_vm._v("提示：往左滑动查看欠条")]) : _vm._e(), _vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (client) {
    return _c('van-swipe-cell', {
      key: client.id,
      scopedSlots: _vm._u([{
        key: "right",
        fn: function fn() {
          return [_c('van-button', {
            staticStyle: {
              "height": "100%"
            },
            attrs: {
              "square": "",
              "type": "primary",
              "text": "欠条"
            },
            on: {
              "click": function click($event) {
                return _vm.toIouView(client);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('van-cell', {
      attrs: {
        "title": client.name,
        "value-class": "cell-value",
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toDetailView(client.id);
        }
      }
    }, [client.unpaid_amount != 0 ? _c('div', [_vm._v(" 签单:￥" + _vm._s(client.unpaid_amount) + " ")]) : _vm._e(), client.surplus_amount != 0 ? _c('div', [_vm._v(" 欠条:￥" + _vm._s(client.surplus_amount) + " ")]) : _vm._e()])], 1);
  })], 2)], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.onSelect
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }), _c('my-dialog', {
    attrs: {
      "title": "添加欠条"
    },
    on: {
      "confirm": _vm.addIouConfirm
    },
    model: {
      value: _vm.showIouDialog,
      callback: function callback($$v) {
        _vm.showIouDialog = $$v;
      },
      expression: "showIouDialog"
    }
  }, [_c('div', {
    staticClass: "iou-container"
  }, [_c('van-cell', {
    attrs: {
      "title": "客户名称",
      "value": _vm.iouData.client_name,
      "center": ""
    }
  }), _c('van-field', {
    attrs: {
      "label": "欠条金额",
      "input-align": "right",
      "placeholder": "请输入欠条金额",
      "type": "number"
    },
    model: {
      value: _vm.iouData.iou_amount,
      callback: function callback($$v) {
        _vm.$set(_vm.iouData, "iou_amount", $$v);
      },
      expression: "iouData.iou_amount"
    }
  }), _c('van-cell', {
    attrs: {
      "title": "欠条日期",
      "value": _vm.iouData.iou_date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showDatePicker = true;
      }
    }
  })], 1)]), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showDatePicker,
      callback: function callback($$v) {
        _vm.showDatePicker = $$v;
      },
      expression: "showDatePicker"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择日期"
    },
    on: {
      "confirm": _vm.handleSelectDate,
      "cancel": function cancel($event) {
        _vm.showDatePicker = false;
      }
    },
    model: {
      value: _vm.currentDate,
      callback: function callback($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }